import * as angular from 'angular';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { AssetClassCodes } from '@common/enums';
import { ApiService } from '@services/api.service';
import { DataEntityService } from '@services/data-entity.service';
import { DupeHandlerService } from '@services/dupe-handler.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { Asset } from 'src/app/_DBContext/Asset';
import { AssetClass } from 'src/app/_DBContext/AssetClass';
import { BaseController } from 'src/app/base.controller';

export class AddGroupDialogController extends BaseController {
  public newName: string;
  public nameExists = false;
  public assets = [] as fuse.groupProfileDto[];
  public isSuperUser: boolean;
  public groupTypes: string[] = [];
  public selectedGroupType: string;

  private _mdDialog: angular.material.IDialogService;
  private _state: angular.ui.IStateService;
  private _apiService: ApiService;
  private _dataEntityService: DataEntityService;
  private _dupeHandlerService: DupeHandlerService;
  private _languageService: LanguageService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    ApiService: ApiService,
    DataEntityService: DataEntityService,
    DupeHandlerService: DupeHandlerService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    assets: fuse.groupProfileDto[],
    groupTypes: string[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._state = $state;
    this._apiService = ApiService;
    this._dataEntityService = DataEntityService;
    this._dupeHandlerService = DupeHandlerService;
    this._languageService = LanguageService;

    this._dupeHandlerService.setDuplicateMessage('AC.GROUP.GROUP_ALREADY_EXISTS');

    this.entityManager = DataEntityService.manager;
    this.isSuperUser = PermissionService.isSuperUser;
    this.assets = assets;
    this.groupTypes = groupTypes;

    if (this.groupTypes.length === 1) {
      this.selectedGroupType = this.groupTypes[0];
    }
  }

  public closeDialog() {
    if (this.scope['addGroupForm'].$dirty) {
      const confirm = this._languageService.closeDialog();
      this._mdDialog.show(confirm).then(
        () => {
          this._mdDialog.hide();
        },
        () => {
          // user canceled the close - don't close the form
        },
      );
    } else {
      this._mdDialog.hide();
    }
  }

  public checkGroupName() {
    if (this.newName) {
      if (this.assets.some((a) => a.name.toLowerCase() == this.newName.toLowerCase())) {
        this.nameExists = true;
      } else {
        this.nameExists = false;
      }
    } else {
      this.nameExists = false;
    }
  }

  public submit() {
    const now: Date = new Date();

    const createSiteGroup = () => {
      const createGroup = (assetClasses: AssetClass[]): Asset => {
        const createDefaultSiteWithSettings = (groupId: number) => {
          const siteType: any = this.entityManager.metadataStore.getEntityType('Site');
          const newSite = siteType.createEntity({ AssetId: groupId });

          this.entityManager.addEntity(newSite);

          // Crop Site Settings.
          const siteSettingsCropDefault = this._dataEntityService.getDefault('SiteSettingsCrop', now);

          siteSettingsCropDefault.AssetId = groupId;

          const siteSettingsCropType: any = this.entityManager.metadataStore.getEntityType('SiteSettingsCrop');
          const newSiteSettingsCrop = siteSettingsCropType.createEntity(siteSettingsCropDefault);

          this.entityManager.addEntity(newSiteSettingsCrop);

          // Nutrient Site Settings.
          const siteSettingsNutrientsDefault = this._dataEntityService.getDefault('SiteSettingsNutrients', now);

          siteSettingsNutrientsDefault.AssetId = groupId;

          const siteSettingsNutrientsType: any = this.entityManager.metadataStore.getEntityType('SiteSettingsNutrients');
          const newSiteSettingsNutrients = siteSettingsNutrientsType.createEntity(siteSettingsNutrientsDefault);

          this.entityManager.addEntity(newSiteSettingsNutrients);

          // Soil Site Settings.
          const siteSettingsSoilDefault = this._dataEntityService.getDefault('SiteSettingsSoil', now);

          siteSettingsSoilDefault.AssetId = groupId;
          siteSettingsSoilDefault['WorkingSoilDepthTop_mm'] = 0;

          const siteSettingsSoilType: any = this.entityManager.metadataStore.getEntityType('SiteSettingsSoil');
          const newSiteSettingsSoil = siteSettingsSoilType.createEntity(siteSettingsSoilDefault);

          this.entityManager.addEntity(newSiteSettingsSoil);

          // Water Site Settings.
          const siteSettingsWaterDefault = this._dataEntityService.getDefault('SiteSettingsWater', now);

          siteSettingsWaterDefault.AssetId = groupId;

          const siteSettingsWaterType: any = this.entityManager.metadataStore.getEntityType('SiteSettingsWater');
          const newSiteSettingsWater = siteSettingsWaterType.createEntity(siteSettingsWaterDefault);

          this.entityManager.addEntity(newSiteSettingsWater);
        };

        const assetType: any = this.entityManager.metadataStore.getEntityType('Asset');
        const assetClass = assetClasses.find(x => x.Name === 'Site Group (CMU)');
        const newAsset: any = assetType.createEntity({
          OwnerAccountId: this.accountId,
          AssetClassId: assetClass.Id,
          Name: this.newName,
          Status: 'Active',
          Comment: this._languageService.instant('AC.GROUP.GROUP_IS_COLLECTION'),
        });

        const group = this.entityManager.addEntity(newAsset) as Asset;

        createDefaultSiteWithSettings(group.AssetId);

        return group;
      };

      const qryAssetClass = new breeze.EntityQuery('AssetClass');

      this.entityManager.executeQuery(qryAssetClass).then(
        (data) => {
          const group = createGroup(data.results as AssetClass[]);

          this._dataEntityService.saveChanges(true, this._dupeHandlerService.duplicatesOnly).then(
            () => {
              const groupId = group.AssetId;

              LocalStorageUtils.updateContextData((context) => {
                context.groupId = groupId;
              });

              this._mdDialog.hide();
              this._state.go('app.account.groups.detail', { id: groupId });
            },
            (err) => {
              this._dupeHandlerService.showError(err);
              this._dataEntityService.rejectChanges();
            },
          );
        }
      );
    };

    const createAssetGroup = () => {
      const newGroup = { name: this.newName, classCode: this.selectedGroupType };

      const successCallback = (groupId: number) => {
        LocalStorageUtils.updateContextData((context) => {
          context.groupId = groupId;
        });

        this._mdDialog.hide();
        this._state.go('app.account.assetgroups.detail', { id: groupId });
      };

      this._apiService.postHandleDupes('group/addGroup', newGroup, successCallback, newGroup.classCode);
    };

    if (this.selectedGroupType === AssetClassCodes.SiteGroup) {
      createSiteGroup();
    } else {
      createAssetGroup();
    }
  }
}

angular.module('app.account').controller('AddGroupDialogController', AddGroupDialogController);
