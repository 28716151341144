import * as angular from 'angular';
import * as moment from 'moment';
import { PermissionService } from '@services/permission.service';
import { SWANConstants } from '@common/SWANConstants';
import { BaseController } from 'src/app/base.controller';

export interface KCRolloverSettings {
  dateFrom: Date;
  dateTo: Date;
  targetYear: number;
  rolloverWorkingDepths: boolean;
}

export class GroupKcRolloverDialogController extends BaseController {
  public dateFrom: Date;
  public dateTo: Date;
  public minDate: Date;
  public maxDate: Date;
  public targetYear: number;
  public minimumYear: number;
  public rolloverWorkingDepth: boolean;

  private _mdDialog: angular.material.IDialogService;
  private _siteIds: number[];

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
    siteIds: number[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;

    this._siteIds = siteIds;
    this.minDate = SWANConstants.MinDate;
    this.minimumYear = new Date().getFullYear() - 1;
  }

  public rolloverEnabled(): boolean {
    return (
      this.dateFrom != null &&
      this.dateTo != null &&
      this.targetYear != this.dateFrom.getFullYear() &&
      this.targetYear != null &&
      this.targetYear >= this.minimumYear
    );
  }

  public fromSelected() {
    this.maxDate = moment(this.dateFrom).toDate().addYears(1).addDays(-1);

    if (this.dateTo != null && this.dateTo <= this.dateFrom) {
      this.dateTo = moment(this.dateFrom).toDate().addDays(1);
    }

    if (this.dateTo != null && this.dateTo > this.maxDate) {
      this.dateTo = this.maxDate;
    }
  }

  public rollover() {
    const settings = {
      dateFrom: new Date(Date.UTC(this.dateFrom.getFullYear(), this.dateFrom.getMonth(), this.dateFrom.getDate())),
      dateTo: new Date(Date.UTC(this.dateTo.getFullYear(), this.dateTo.getMonth(), this.dateTo.getDate())),
      targetYear: this.targetYear,
      rolloutWorkingDepth: this.rolloverWorkingDepth,
      siteIds: this._siteIds,
    } as fuse.siteSettingsRolloverDto;
    this._mdDialog.hide(settings);
  }

  public closeDialog() {
    this._mdDialog.hide();
  }
}

angular.module('app.account').controller('GroupKcRolloverDialogController', GroupKcRolloverDialogController);
