import * as angular from 'angular';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { EntityList } from '@common/EntityList';
import { AssetClassCodes, UnitTypes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { GroupSiteService } from '@services/group-site.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { PermissionService } from '@services/permission.service';
import { ApiService } from '@services/api.service';
import { EntityListService } from '@services/entity-list.service';
import { BaseController } from 'src/app/base.controller';
import { AddGroupDialogController } from './addgroup-dialog.controller';

export class GroupsController extends BaseController {
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _apiService: ApiService;
  private _entityListService: EntityListService;

  public groupSiteService: GroupSiteService;

  public iActive = 0;
  public iSuspended = 0;
  public iArchived = 0;
  public showActive = true;
  public showArchived = false;
  public showSuspended = false;
  public groupRows: EntityList<fuse.groupListDto>;

  public areaUnit: uomUnit;
  public groupTypes: string[];
  public hasGroupTypes: boolean;
  public siteGroupType = AssetClassCodes.SiteGroup;
  public isSiteGroup: boolean = true;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    ApiService: ApiService,
    EntityListService: EntityListService,
    GroupSiteService: GroupSiteService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.GroupFull);

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._apiService = ApiService;
    this._entityListService = EntityListService;

    this.groupSiteService = GroupSiteService;
    this.areaUnit = UnitOfMeasureService.getUnits(UnitTypes.Area);

    this.loadAssetClass();
  }

  $onInit() {
    this.getGroupTypes();
    this.getAccountGroupProfiles();
  }

  public loadAssetClass() {
    const type = this.groupSiteService.filters[this.groupSiteService.groupClassFilter] || this.siteGroupType; // default value
    this.groupSiteService.filters[this.groupSiteService.groupClassFilter] = type;
    this.isSiteGroup = type == this.siteGroupType;
  }

  public getGroupTypes() {
    const afterFunc = (result) => {
      this.groupTypes = result.map((r) => r.name);
    };
    this._apiService.getGeneric('group/getGroupTypes', null, afterFunc);
  }

  public changeType(type: string) {
    this.groupRows.filters[this.groupSiteService.groupClassFilter] = type;
    this.isSiteGroup = type == this.siteGroupType;
    this.loadSiteInfo();
  }

  private getAccountGroupProfiles() {
    const hasGroupTypes = (): boolean => {
      if (this.groupRows.entities?.length > 1) {
        const first = this.groupRows.entities[0].assetClass;

        return this.groupRows.entities.some((a) => a.assetClass !== first);
      }

      return false;
    }

    const func = (response) => {
      this.groupRows = response;
      this.hasGroupTypes = hasGroupTypes();

      this.loadAssetClass();
      this.groupSiteService.setKeepFilter(false);
      this.loadSiteInfo();
    };

    this._entityListService.getEntityList('group/getAccountGroupProfiles', func, true, null, this.groupSiteService);
  }

  public waterSummaryClass(status, reading: string) {
    if (status) {
      switch (reading) {
        case 'low':
          if (status.low > 0) return 'icon-arrow-down-bold-circle s24 swanOrange';
          else return 'icon-arrow-down-bold-circle s24 swanLightGrey';
        case 'ok':
          if (status.ok > 0) return 'icon-checkbox-marked-circle s24 swanGreen';
          else return 'icon-checkbox-marked-circle s24 swanLightGrey';
        case 'high':
          if (status.high > 0) return 'icon-arrow-up-bold-circle s24 swanRed';
          else return 'icon-arrow-up-bold-circle s24 swanLightGrey';

        default:
          break;
      }
    }

    return '';
  }

  public loadSiteInfo() {
    if (this.groupRows.filters[this.groupSiteService.groupClassFilter] != this.siteGroupType) {
      return;
    }

    // Don't fetch if already has Group Summary SubInfo
    const filteredAssetIds = this.groupRows
      .filterEntities()
      .filter((cmu) => cmu.subInfo == null)
      .map((cmu) => cmu.id);

    if (filteredAssetIds.length) {
      const subInfoParams = { accountId: this.accountId, assetIds: filteredAssetIds };

      // Fetch group health readings, soil moisture and water usages
      this._http
        .get(CommonHelper.getApiUrl('user/AccountGroupSummarySubInfo'), { params: subInfoParams })
        .then((response) => {
          (response.data as fuse.accountGroupSubInfo[]).forEach((subInfo) => {
            const row = this.groupRows.entities.find((gr) => gr.id == subInfo.assetId);
            row.subInfo = subInfo;
          });
        });
    }
  }

  public openAddGroupDialog() {
    this._mdDialog
      .show({
        controller: AddGroupDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/account/views/groups/addgroup-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          assets: this.groupRows.entities,
          groupTypes: this.groupTypes,
        },
      })
      .then(() => {});
  }
}

angular.module('app.account').controller('GroupsController', GroupsController);
